@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  padding-bottom: 90px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.actionBarForProductLayout {
  margin: 24px;
  width: calc(100% - 48px);

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}

.actionBarContainerForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actionBarForHeroLayout {
  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 24px 11px 24px;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.addPayoutDetails,
.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 14px 24px 11px 24px;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 24px 24px 24px 12px;
  }
}

.missingPayoutDetailsText {
  color: var(--colorAttention);
}

.payoutDetailsWarning {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    /* max-width: calc(100% - 360px);
    flex-basis: calc(100% - 360px);
    flex-shrink: 0;
    flex-grow: 1; */
  }
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-left: 48px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.contentWrapperForProductLayout {
  margin: 0 0 56px 0;

  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    padding: 0 24px 0 24px;
    margin: 0 auto 56px auto;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    max-width: unset;
    width: min(calc(var(--contentMaxWidth) + 72px), 100vw);
    padding: 64px 36px 0 36px;
    margin: 0 auto 117px auto;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    /* margin-top: 24px; */
  }
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flexbox would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.sectionHeadingWithExtraMargin {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
  .avgRatingHeading{
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 4px;
    .ratingStar{
      margin-right: 2px;
    }
    .separator{
      font-family: Inter;
      font-size: 12px;
      margin: 0 2px;
      border-radius: 100%;
    }
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionMap {
  padding: 0 0px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.sectionReviews,
.sectionAuthor {
  padding: 0 0px;
  margin-top: 42px;
  scroll-margin-top: calc(var(--topbarHeight) + 24px);

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
    scroll-margin-top: calc(var(--topbarHeightDesktop) + 24px);
  }
  @media (--viewportLarge) {
    padding: 8px 0;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
}

.longWord {
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 24px 0 48px 0;
  }
  @media (--viewportLarge) {
    margin: 64px 0 40px 0;
    padding-bottom: 4px;
    padding: 0;
  }
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.container {
  width: 100%;
  max-width: 1270px;
  margin: auto;
  padding: 0 15px;
}

.listingDetailContainer {
  p {
    font-family: Sora;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #666666;
  }
  h3,
  h4, h2 {
    font-size: 40px;
    margin: 16px 0;
    line-height: normal;
    text-align: left;
    color: #333333;
    padding: 0;
    span{
      color: #333333;
    }
    @media screen and (width < 575px){
      font-size: 30px;
    }
  }
  margin-top: 64px;
  .listingHeading {
    .subCategoriesContainer {
      display: flex;
      gap: 16px;
      align-items: center;

      .subCategory {
        background-color: var(--marketplaceColor);
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #ffffff;
        border-radius: 4px;
        padding: 6px 8px;
        @media screen and (width < 575px){
          font-size: 12px;
        }
      }
    }
    .orderPanelTitle {
      margin: 20px 0 16px;
      font-family: Sora;
      font-size: 56px;
      font-weight: 700;
      line-height: 67.2px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      @media screen and (width < 992px){
        font-size: 40px;
      }
      @media screen and (width < 575px){
        line-height: normal;
        font-size: 30px;
      }
    }
    .locationWrappper {
      display: flex;
      align-items: center;
      gap: 6px;
      .locationText {
        font-family: Sora;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #666666;
      }
    }
  }

 @media screen and (width < 767px){
  .mainColumnForProductLayout {
    .productGallery {
      margin-top: 25px;
    }
  }
 }
}

.listingBookingContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 64px;
  border-bottom: 1px solid gray;
  padding-bottom: 64px;

  .descriptionContainer {
    .descriptionWrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      .sectionText {
        margin: 0;
      }
    }
    p.sideTextBlock {
      margin-bottom: 50px;
    }
  }
  @media screen and (width < 1024px){
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 25px;
    margin-bottom: 25px;
    gap: 25px;
  }
  @media screen and (width < 767px){
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 25px;
    margin-bottom: 25px;
    
  }
}

.otherDetailsContainer {
  .leftSection {
    width: 100%;
    max-width: calc(100% - 480px);
    padding-right: 40px;
    float: left;
    .otherDetailsWrapper {
      .cardWrapper {
        border-width: 1px;
        border-style: solid;
        border-color: rgb(38, 43, 186);
        border-image: initial;
        margin-bottom: 24px;
        padding: 24px;
        border-radius: 8px;
        .cardHeading {
          font-family: Sora;
          font-size: 22px;
          font-weight: 700;
          line-height: 27.72px;
          text-align: left;
          color: #262bba;
        }
        .cardBodyText {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          margin-top: 24px;

          .bodyItem {
            p {
              font-family: Sora;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              text-align: left;
              color: #212121;
              padding-right: 100px;
              @media screen and (width < 1200px){
                padding-right: 0;
              }
            }
          }
        }
        .cardBodyServicesText {
          margin-top: 24px;
          .bodyItem {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
            @media screen and (width < 1200px){
              gap: 8px;
             
             }
             @media screen and (width < 575px){
              display: unset;            
             }
            p {
              text-align: left;
              width: 100%;
              display: flex;
              align-items: center;
              gap: 8px;
              svg{
                min-width: 24px;
              }
              @media screen and (width < 1200px){
               margin: 0;              
              }
              @media screen and (width < 575px){
                margin-bottom: 15px;          
               }
            }
            p.para{
              grid-column: span 2;
            }
            button {
              grid-column: span 2;
              max-width: max-content;
              border: 0;
              font-family: Inter;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              text-align: left;
              text-decoration-line: underline;
              text-decoration-style: solid;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: #262bba;
              cursor: pointer;
            }
          }
        }
        @media screen and (width < 1200px){
          padding: 15px;
          
         }
         @media screen and (width < 575px){
          .cardBodyText{
            grid-template-columns: repeat(1, 1fr);
            margin-top: 0px;
          }
         
         }
      }
      .pictureGallery{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        padding-top: 16px;
        margin-bottom: 40px;
        .imagesCardBlock{
          max-height: 176px;
                    overflow: hidden;
                    border-radius: 16px;
                    position: relative;
                  img{
                    height: 176px;
                    object-fit: cover;
                  }
                  h6{
                     position: absolute;
                        bottom: 0px;
                        font-size: 16px;
                        color: #ffffff;
                        padding: 15px;
                        transition: bottom 0.2s linear;
                        filter: drop-shadow(9px 4px 3px black);
                  }

                 
        }
      }
    }
  }
  .rightSection {
    float: right;
    width: 100%;
    max-width: 480px;
    padding-left: 40px;
    position: sticky;
        top: 100px;
        .relatedListingCard{
          display: block;
          margin-bottom: 40px;
          > div:first-child{
            background-color: beige;
            div:nth-child(2){
              > div{
                padding-top:57% ;
              }
            }
          }
          > div:nth-child(2){
            > div:nth-child(3){
              button{
                min-height: 38px;
                height: 40px;
                border-radius: 12px !important;
                &:nth-child(2){
                  display: none;
                }
              }
            }
          }
          
        }
  }
  .clearfix {
    clear: both;
  }
  @media screen and (width < 992px){
    .leftSection{
      width: 100%;
      max-width: 100%;
      padding-right: 0;
    }
    .rightSection{
      width: 100%;
      max-width: 100%;
      border-top:1px solid #f2f2f2;
      margin-top: 50px;
      padding-top: 50px;
      padding-left: 0;
    }
  }
}


.sectionMap{
  button{
    width: 100%;
    height: 240px;
    max-width: 100%;
    margin-top: 24px;
    border-radius: 16px;
    overflow: hidden;
    div{
      max-width: 100%!important;
    }
  }
}

.careGiverDescriptionContainer{
  .labelWrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    font-size: 14px;
    span{
      font-size: 14px;
    }
    label{
      font-size: 14px;
    }
  }
  .serviceProvider{
    display: flex;
    flex-flow: column;
    label{
      margin-top: 16px;
      font-size: 22px;
    }
    ul{
      padding: 0;
      margin: 0;
      list-style: disc;
      padding-left: 18px;
      li{
        padding-top: 0;
        font-size: 14px !important;
      }
    }
  }
}